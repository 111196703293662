<template>
  <layout-vertical class="overflow-x-hidden">
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <div slot="breadcrumb" class="p-0" />
    <div slot="footer" class="p-0" />
    <!--    <div slot="footer">
      <p class="d-flex justify-content-around align-items-center flex-wrap mb-0">
        <span class="float-md-left d-block d-md-inline-block">
          COPYRIGHT © {{ new Date().getFullYear() }}
          <a href="https://www.omnimenu.io" rel="noopener" target="_blank" class="ml-25"
            >Omnimenu</a
          ><span class="d-none d-sm-inline-block">, All rights Reserved</span></span
        >
        <span class="float-md-right d-md-block"
          >Hand-crafted &amp; Made with
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21px"
            height="21px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="text-danger stroke-current feather feather-heart"
          >
            <path
              d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
            /></svg
        ></span>
      </p>
    </div>-->
    <!--    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />-->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>

<style>
.overflow-x-hidden {
  overflow-x: hidden;
}
.footer {
  padding: 0 !important;
  min-height: 0 !important;
}
</style>
